<template>
    <AppLayout>
        <v-snackbar v-model="snackbarAccepted" :timeout="2000" top color="blue" class="mt-0 pt-0">
            <span>Accepted invitation</span>
            <v-btn text class="blue white--text" @click="snackbarAccepted = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
        </v-snackbar>

        <Tutorial>
            <p class="text-subtitle-1 font-weight-light text-center">You are invited to join an existing LoginShield Enterprise account. Accepting the invitation will not affect any other account you might have. You can return to this page by clicking the same link in your email again. It will expire after a few days.</p>
        </Tutorial>
        <v-row justify="center" class="py-5">
            <h1 class="text-h6 font-weight-light" v-if="!showLoginPrompt">You are invited</h1>
            <h1 class="text-h6 font-weight-light" v-if="showLoginPrompt">Login to continue</h1>
        </v-row>
        <v-row justify="center" class="py-5" v-if="!showLoginPrompt">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card elevation="6" class="px-10 pb-5">
                    <v-card-text class="text-h5 text-center">{{accountName}}</v-card-text>
                    <v-divider class="mx-5 mb-5"></v-divider>
                    <v-row justify="start" class="pt-5" v-show="accountType === ACCOUNT_TYPE_ENTERPRISE">
                        <p class="text-body-1 font-weight-light">
                            You are invited to join this enterprise account to login to enterprise applications.
                        </p>
                        <p class="text-body-1 font-weight-light">
                            The account administrator manages this account. Your use of an enterprise account will be visible to the enterprise administrator. However, the enterprise administrator will <b>NOT</b> have any access to any other LoginShield account that you may have.
                        </p>
                    </v-row>
                    <v-list>
                        <v-list-item style="border: 1px solid #e0e0e0; margin-bottom: 10px;">
                            <v-list-item-avatar>
                                <v-avatar :size="40" style="margin: 0px;">
                                    <template>
                                        <Avatar :attr=accountUser :size="80"></Avatar>
                                    </template>
                                </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content class="ml-5">
                                <v-list-item-title class="text-h6 mb-2" v-text="accountUser.name"></v-list-item-title>
                                <v-list-item-subtitle class="mb-2" v-text="accountUser.email" style="margin-left: 1px;"></v-list-item-subtitle>
                                <v-list-item-subtitle class="mb-2" v-text="accountUser.phone" style="margin-left: 1px;"></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-card-actions>
                        <v-row justify="center">
                            <v-btn elevation="6" class="grey white--text" @click="decline">
                                <font-awesome-icon icon="times" fixed-width class="mr-2"/>
                                Decline
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn elevation="6" class="blue white--text" @click="accept">
                                <font-awesome-icon icon="check" fixed-width class="mr-2"/>
                                Accept
                            </v-btn>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="center" class="py-5" v-if="showLoginPrompt">
            <v-col cols="6" sm="5" md="4" lg="3" xl="2" class="pa-0">
                <v-card elevation="6" class="px-10 pb-5">
                    <v-card-text class="text-h5 text-center">New to LoginShield?</v-card-text>
                    <v-divider class="mx-5 mb-5"></v-divider>
                    <v-row justify="start" class="pt-5">
                        <p class="text-body-1 font-weight-light">
                            If you don't yet have an account, get the app and create a free account. When you have a free account, click the link in the email again to return to this page to login and accept the invitation.
                        </p>
                    </v-row>
                    <v-card-actions>
                        <v-row justify="center">
                            <v-btn elevation="6" class="blue white--text" href="/software/">
                                <font-awesome-icon icon="check" fixed-width class="mr-2"/>
                                Get the app
                            </v-btn>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="6" sm="5" md="4" lg="3" xl="2" class="pa-0">
                <v-card elevation="6" class="px-10 pb-5">
                    <v-card-text class="text-h5 text-center">Already using LoginShield?</v-card-text>
                    <v-divider class="mx-5 mb-5"></v-divider>
                    <v-row justify="start" class="pt-5">
                        <p class="text-body-1 font-weight-light">
                            If you already have an account, click the <b>Login</b> button to login
                            and return to this page.
                        </p>
                    </v-row>
                    <v-card-actions>
                        <v-row justify="center">
                            <v-btn elevation="6" class="blue white--text" @click="login">
                                <font-awesome-icon icon="check" fixed-width class="mr-2"/>
                                Login
                            </v-btn>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </AppLayout>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import Avatar from '@/components/Avatar.vue';
import Tutorial from '@/components/Tutorial.vue';
import AppLayout from '@/components/AppLayout.vue';
import { ACCOUNT_TYPE_ENTERPRISE } from '@/constants';

export default {
    components: {
        AppLayout,
        Avatar,
        Tutorial,
    },

    data() {
        return {
            interactionId: null,
            showLoginPrompt: false,
            snackbarAccepted: false,
            accountName: null,
            accountType: null,
            accountUser: {
                name: null,
                email: null,
                phone: null,
                isGravatarEnabled: null,
                icon: null,
            },
        };
    },

    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
            user: (state) => state.user,
            interactionMap: (state) => state.interactionMap,
        }),
        // ...mapGetters({
        // currentAccount: 'account', // currently selected account object
        // }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        ACCOUNT_TYPE_ENTERPRISE() {
            return ACCOUNT_TYPE_ENTERPRISE;
        },
    },

    watch: {
        isReady(val, oldVal) {
            if (val && !oldVal) {
                this.init();
            }
        },
    },

    methods: {
        async init() {
            this.interactionId = this.$route.query.i;
            console.log('linkaccountuser.vue: init, isAuthenticated: %o', this.isAuthenticated);
            console.log('linkaccountuser.vue: init, interactionId: %o', this.interactionId);
            if (!this.interactionId) {
                console.log('linkaccountuser: init, interaction id rqeuired');
                return;
            }
            // check if we already have the interaction info cached
            let response = this.interactionMap[this.interactionId];
            if (!response) {
                response = await this.$store.dispatch('loadInteraction', this.interactionId);
            }
            console.log('register.vue: interaction: %s', JSON.stringify(response));
            const { type, state } = response;
            if (type === 'link_account_user') {
                this.email = state.email;
                this.accountName = state.accountName;
                this.accountType = state.accountType;
                this.accountUser.name = state.name;
                this.accountUser.email = state.email;
                this.accountUser.phone = state.phone;
            }
            this.interactionType = type;
        },
        async accept() {
            console.log('accept');
            if (this.isAuthenticated) {
                await this.$store.dispatch('acceptInvitation', { interactionId: this.interactionId });
                this.$router.push('/dashboard');
            } else {
                this.showLoginPrompt = true;
            }
        },
        decline() {
            console.log('decline');
            if (this.isAuthenticated) {
                this.$router.push('/dashboard');
            } else {
                this.$router.push('/');
            }
        },
        login() {
            // show the login page, and capture the current page with query parameters so we can return user here after login
            this.$router.push({ path: '/login', query: { from: this.$route.fullPath } });
        },
    },

    created() {
        console.log('linkaccountuser.vue: created');
    },

    mounted() {
        console.log('linkaccountuser.vue: mounted');
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
